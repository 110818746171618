import { expandToBackendURL } from '@plone/volto/helpers';
export const GET_EVENTS_LOCATIONS = 'GET_ERGO_EVENTS_LOCATIONS';
export const GET_EVENTS_KEYWORDS = 'GET_EVENTS_KEYWORDS';

export const getEventsLocations = (path = '') => {
  const pathSearchFilters = `${
    path === '/' ? '' : expandToBackendURL(path)
  }/@search-events-filters`;

  return {
    type: GET_EVENTS_LOCATIONS,
    subrequest: path,
    request: {
      op: 'get',
      path: pathSearchFilters,
      params: {
        type: 'locations',
      },
    },
  };
};

export const getEventsKeywords = (path) => {
  const pathSearchFilters = `${
    path === '/' ? '' : expandToBackendURL(path)
  }/@search-events-filters`;
  return {
    type: GET_EVENTS_KEYWORDS,
    subrequest: path,
    request: {
      op: 'get',
      path: pathSearchFilters,
      params: {
        type: 'keywords',
      },
    },
  };
};
