/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from 'design-comuni-plone-theme/reducers';
import {
  searchEventsKeywords,
  searchEventsLocations,
} from './eventsSearchBlock';
/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  searchEventsLocations: searchEventsLocations,
  searchEventsKeywords: searchEventsKeywords,
  // Add your reducers here
};

export default reducers;
