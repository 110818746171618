//import { useSelector } from 'react-redux';
import DefaultFilters from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/EventSearch/DefaultFilters';
import EventsLocationWidget from '@package/components/Blocks/EventsSearch/widgets/EventsLocationWidget';
import EventsKeywordsWidget from '@package/components/Blocks/EventsSearch/widgets/EventsKeywordsWidget';
/*
  ***
  componente da customizzare nel proprio sito per modificare/aggiungere tipologie di Filtri
  ***
 */
const FiltersConfig = (props) => {
  // const subsite = useSelector((state) => state.subsite?.data);
  const defaultFilters = DefaultFilters();
  const path = props?.data?.location?.[0]?.['@id'] ?? '';

  return {
    ...defaultFilters,
    venue_filter: {
      ...defaultFilters.venue_filter,
      widget: {
        ...defaultFilters.venue_filter.widget,
        component: EventsLocationWidget,
        props: {
          ...defaultFilters.venue_filter.widget.props,

          options: {
            ...defaultFilters.venue_filter.widget.props.options,
            path,
            dispatch: null,
          },
        },
      },
    },
    keywords_filter: {
      label: 'Parole chiave',
      type: 'keyword_filter',
      widget: {
        component: EventsKeywordsWidget,
        props: {
          value: null,
          options: {
            path,
            placeholder: 'Parole chiave',
          },
        },
      },
      query: (value, query) => {
        if (value?.value) {
          query.push({
            i: 'Subject',
            o: 'plone.app.querystring.operation.selection.any',
            v: value.value,
          });
        }
      },
    },
  };
};

export default FiltersConfig;
