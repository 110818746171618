/*Customizations:
- pass custom parameters to CardWithImageTemplate
*/
import React from 'react';
import CardWithImageTemplate from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/Listing/CardWithImageTemplate';
const RenderItems = ({ items }) => {
  return (
    <>
      <CardWithImageTemplate
        items={items}
        full_width={false}
        always_show_image={true}
        natural_image_size={true}
        show_icon={true}
        show_section={true}
        hide_dates={true}
        show_description={true}
        show_topics={true}
      />
    </>
  );
};
export default RenderItems;
