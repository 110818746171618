/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { SelectInput } from 'design-comuni-plone-theme/components';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsLocations } from '@package/actions';

const EventsLocationWidget = ({ options, value, id, onChange }) => {
  const dispatch = useDispatch();
  const path = options?.path?.length > 0 ? options.path : '/';

  const selectOptions = useSelector((state) => {
    const results = state.searchEventsLocations?.subrequests?.[path]?.result;
    if (!results) {
      return [];
    }
    if (Object.keys(results).length === 0 && results.constructor === Object) {
      return [];
    }
    return results;
  });

  useEffect(() => {
    dispatch(getEventsLocations(path));
  }, [options]);

  return (
    <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
      <SelectInput
        id={id}
        value={value}
        placeholder={options?.placeholder}
        onChange={(opt) => {
          onChange(id, opt);
        }}
        options={selectOptions?.filter((opt) => !!opt.value?.toString()) ?? []}
        isClearable={true}
      />
    </div>
  );
};

export default EventsLocationWidget;
